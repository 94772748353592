import { IconBtn } from '@/Buttons/IconBtn';
import { flexCenter, px } from '@/layout/styles/classes';
import Slider from '@/Slider';
import { Video } from '@/Video';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    IGatsbyImageData,
    ImageDataLike,
    withArtDirection
} from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { Navigation, Pagination } from 'swiper';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import "swiper/css/pagination";
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';
import rightArrow from '../../assets/images/icons/right-arrow.svg';

const TopReviews = styled.div`
    background-image: linear-gradient(
        to bottom,
        ${({ theme }) => theme.colors.purple5} 96px,
        transparent 96px
    );
    padding-top: 96px;
    display: grid;
    grid-area: 1/1;
    align-self: center;
    grid-template-columns: minmax(0, 1fr); //must for swiper inside grid

    @media (min-width: 1024px) {
        background-image: none;
        padding: 0;
        margin-top: 120px;
    }
`;

const SliderWrapper = styled.div`
    ${px};
    z-index: 2;
    grid-area: 1/1;
    margin-top: -96px;
    align-self: start;
    position: relative;

    @media (min-width: 1024px) {
        justify-content: center;
        margin-top: 0;
    }
`;

const PaginationDiv = styled.div`
    ${flexCenter};
    position: absolute;
    bottom: -45px !important;
    background-color: rgba(255, 255, 255, 0.78);
    width: fit-content !important;
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 15px;

    > span {
        font-size: 0.875rem;
        margin: 0 16px;
    }
`;

const reviewSlideStyles = css`
    margin: 0 auto;

    &,
    > * {
        height: min(50vw, 319px);
        aspect-ratio: 343 / 210;
    }

    @media (min-width: 1024px) {
        max-width: 720px;

        &,
        > * {
            height: min(50vw, 442px);
            aspect-ratio: 738 / 453;
        }
    }

    @media (min-width: 1150px) {
        max-width: 738px;

        &,
        & > * {
            height: min(50vw, 453px);
        }
    }

    @supports (aspect-ratio: 1/1) {
        &,
        & > * {
            height: unset;
        }
    }
`;

const mobileBgStyles = css`
    z-index: 1;
    aspect-ratio: 483 / 240;
    min-height: 215px;
    grid-area: 1/1;
    align-self: end;
    max-height: 350px;
    opacity: 0.5;

    img {
        object-position: bottom;
    }

    @media (min-width: 600px) {
        aspect-ratio: unset;
        margin: 0;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`;

const SliderBtn = styled(IconBtn)`
    width: 31px;
    height: 31px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    position: absolute;
    bottom: -47px;
    left: calc(50% - 132px);
    z-index: 5;
    background: rgba(255, 255, 255, 0.78);
    transition: border-color 0.3s ease-in-out;

    :first-of-type {
        > img {
            transform: scaleX(-1);
        }
    }

    :last-of-type {
        left: unset;
        right: calc(50% - 132px);
    }

    > img {
        filter: invert(8%) sepia(5%) saturate(374%) hue-rotate(201deg) brightness(93%) contrast(84%);
    }

    &.swiper-button-lock {
        display: none;
    }

    :hover,
    :focus {
        border-color: ${({ theme }) => theme.colors.purple1};
    }

    &.story-slider-disabled {
        border-color: transparent;
        pointer-events: none;
        background: rgba(255, 255, 255, 0.38);

        > img {
            filter: invert(38%) sepia(4%) saturate(589%) hue-rotate(158deg) brightness(86%)
                contrast(88%);
        }
    }

    @media (min-width: 1024px) {
        width: 56px;
        height: 56px;
        bottom: calc(50% - 28px);
        border-color: ${({ theme }) => theme.colors.white};
        left: calc(50% - 476px);

        :last-of-type {
            right: calc(50% - 476px);
        }
    }

    @media (min-width: 1150px) {
        left: calc(50% - 530px);

        :last-of-type {
            right: calc(50% - 530px);
        }
    }
`;

const desktopBgStyles = css`
    z-index: 1;
    max-height: 732px;
    grid-area: 1/1;
    display: none;
    margin-top: 80px;
    opacity: 0.5;

    @media (min-width: 1024px) {
        display: block;
        min-height: 660px;
    }
`;

export const StoriesSlider = () => {
    type DataTypes = {
        bg: ImageDataLike;
        dummy: ImageDataLike;
        stories: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query storieseBg {
            bg: file(relativePath: { eq: "homepage/stories/other/background.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        breakpoints: [300, 400, 500, 750, 1080, 1366, 1920]
                    )
                }
            }
            dummy: file(relativePath: { eq: "dummy.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            stories: allFile(
                filter: { relativeDirectory: { eq: "homepage/stories" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 768, height: 432, quality: 100)
                    }
                    name
                }
            }
        }
    `);

    const mobileBg = withArtDirection(getImage(data.dummy) as IGatsbyImageData, [
        {
            media: '(max-width: 1023px)',
            image: getImage(data.bg) as IGatsbyImageData,
        },
    ]);

    const desktopBg = withArtDirection(getImage(data.bg) as IGatsbyImageData, [
        {
            media: '(max-width: 1023px)',
            image: getImage(data.dummy) as IGatsbyImageData,
        },
    ]);

    const storyVideos = [
        'gIM8GQwyvWo',
        'ooy8ytYNI_g',
        '9H-9Vk5IjV4',
        'yRyXnYFUD6I',
        'MQt0XQyTsyE',
        '-KMAq5Q0-_c',
        'KCnNmrEVdCc',
        'Q544qBUpI9E',
        'T1II9TudWg4',
        '85fg9ftHm7g',
        'c-PreqKFlVE',
        '0SUAISKLrPA',
        'xUMLJJ5njf4',
        'j0H2gC1y2Vo',
        'b5NJhDLRBOQ',
        'a2UW4Ci22ko',
        'vx5v5leO8uU',
        '0chW4ak_2NM',
        '8kfeoHGaGUI',
        'uyvplW7oBCw',
        'n-wn8hps0ZU',
        'd6ZnjRRQ0Y0',
        'BSIpK78jnts',
        'naCRhVMbSMg',
        'W7b-9eq-iCc'
    ];

    const finalStories = data.stories.nodes.map((node, i) => ({
        image: getImage(node.childImageSharp) as IGatsbyImageData,
        alt: node.name.replace(/[0-9]/g, '').replace(/-/g, ' ').slice(1),
        videoId: storyVideos[i],
    }));

    const sliderSettings = {
        navigation: {
            nextEl: '.story-slider-next',
            prevEl: '.story-slider-prev',
            disabledClass: 'story-slider-disabled',
        },
        pagination: {
            type: 'fraction',
            el: '.story-slider-pagination',
            formatFractionCurrent: function (number: number) {
                return ('0' + number).slice(-2);
            },
            formatFractionTotal: function (number: number) {
                return ('0' + number).slice(-2);
            },
            renderFraction: function (currentClass: string, totalClass: string) {
                return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    ' / ' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                );
            },
        },
        modules: [Navigation, Pagination],
        spaceBetween: 64,
        slidesPerView: 1,
    };

    return (
        <Fragment>
            <TopReviews>
                <SliderWrapper>
                    <SliderBtn className="story-slider-prev" aria-label="go back to previous story">
                        <img src={rightArrow} alt="" />
                    </SliderBtn>
                    <Slider sliderSettings={sliderSettings}>
                        {finalStories.map((story, i) => (
                            <SwiperSlide key={i}>
                                {({ isActive }) => (
                                    <Video
                                        videoId={story.videoId}
                                        css={reviewSlideStyles}
                                        isActive={isActive}
                                    >
                                        <GatsbyImage image={story.image} alt={story.alt} />
                                    </Video>
                                )}
                            </SwiperSlide>
                        ))}
                    </Slider>
                    <PaginationDiv className="story-slider-pagination"></PaginationDiv>
                    <SliderBtn className="story-slider-next" aria-label="slide to next story">
                        <img src={rightArrow} alt="" />
                    </SliderBtn>
                </SliderWrapper>
                <GatsbyImage
                    image={mobileBg}
                    alt="nuttal smiles"
                    css={mobileBgStyles}
                    imgStyle={{ opacity: 0.5 }}
                />
            </TopReviews>
            <GatsbyImage
                image={desktopBg}
                alt="nuttal smiles"
                css={desktopBgStyles}
                imgStyle={{ opacity: 0.5 }}
            />
        </Fragment>
    );
};
